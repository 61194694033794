import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import {
  CustomizationHero,
  CustomizationFlexibility,
  CustomizationValues,
  CustomizationTeam,
} from "src/sections/features/customization"

import ExploreFeatures from "src/sections/features/ExploreFeatures"
import { CtaGetDemo } from "components/cta"

const CustomizationPage = ({ data: { customization } }) => {
  const {
    customization_hero,
    customization_flexible,
    customization_values,
    customization_dedicated_team,
    explore_more_features,
    cta_get_demo,
  } = useContentfulSection(customization.sections)

  const meta = useMeta(customization)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <CustomizationHero {...customization_hero} />
        <CustomizationFlexibility {...customization_flexible} />
        <CustomizationValues {...customization_values} />
        <CustomizationTeam {...customization_dedicated_team} />

        <ExploreFeatures {...explore_more_features} />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    customization: contentfulPage(pageId: { eq: "customization" }) {
      ...Page
    }
  }
`

export default CustomizationPage
