import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import { VerticalSection, Media, TextGroup } from "components/common"

import { featureColors, device } from "utils"

const CustomizationValues = ({ hat, title, subtitle, valueProps }) => {
  const renderCustomizationValues = () => (
    <CustomizationGrid>
      {map(valueProps, ({ id, title, subtitle, media }) => (
        <CustomizationValue key={id}>
          <TextGroup title={title} titleAs="h3" subtitle={subtitle} />
          <div className="mediaWrap">
            <Media media={media} />
          </div>
        </CustomizationValue>
      ))}
    </CustomizationGrid>
  )

  return (
    <StyledCustomizationValues>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        grid={renderCustomizationValues}
      />
    </StyledCustomizationValues>
  )
}

const StyledCustomizationValues = styled.div`
  --tg-hat-color: ${featureColors.customization};
  --tg-max-width: 455px;
  --section-max-width: 1080px;
`

const CustomizationGrid = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--sp-56);

  @media ${device.laptop} {
    grid-gap: 100px;
  }
`
const CustomizationValue = styled.li`
  display: grid;
  align-items: flex-start;
  /* grid-gap: var(--sp-24); */
  grid-template-columns: 1fr;

  --tg-grid-gap: var(--sp-4);

  @media ${device.laptop} {
    /* grid-gap: 150px; */
    grid-gap: var(--sp-32);
    grid-template-columns: 320px 1fr;
  }

  .mediaWrap {
    position: relative;
    left: calc(var(--section-mobile-padding) * -1);
    margin-right: calc(var(--section-mobile-padding) * -2);

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    @media ${device.tablet} {
      left: calc(var(--sp-64) * -1);
      margin-right: calc(var(--sp-64) * -2);
    }

    @media ${device.laptop} {
      margin: 0;
      margin-top: -2.7%;
      left: initial;
      margin-right: initial;
    }
  }
`

export default CustomizationValues
